import React, { useEffect, useRef, useState } from 'react';
import '@google/model-viewer/dist/model-viewer-umd';
import { modelUrls, imageUrls } from 'config';
import { Button } from 'antd';
import { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import { Material } from '@google/model-viewer/lib/features/scene-graph/material';

const frameSrc = modelUrls['21x30'];
const imageSrc = `${process.env.PUBLIC_URL}/resources${imageUrls.Architecture}`;
export const ButtonDemo: React.FunctionComponent = () => {
  const mwRef = useRef<ModelViewerElement>(null);
  const [listenerAdded, setListenerAdded] = useState(false);
  const width = 100;
  const height = 40;
  useEffect(() => {
    if (!mwRef.current) {
      return;
    }
    if (listenerAdded) {
      return;
    }

    setListenerAdded(true);
    mwRef.current.addEventListener('load', () => {
      if (!mwRef.current) {
        return;
      }
      const posterMaterial = mwRef.current.model?.materials[3] as Material;
      mwRef.current.createTexture(imageSrc).then((texture) => {
        posterMaterial.pbrMetallicRoughness.baseColorTexture.setTexture(
          texture
        );
      });
    });
  }, [mwRef, listenerAdded]);
  return (
    <div style={{ margin: '30px' }}>
      <model-viewer
        src={`${process.env.PUBLIC_URL}/resources${frameSrc}`}
        ar
        ar-modes="quick-look scene-viewer webxr"
        ar-placement="wall"
        style={{ width: `${width}px`, height: `${height}px` }}
        ref={mwRef}
      >
        <div slot="canvas" />
        <Button
          slot="ar-button"
          style={{ width: `${width}px`, height: `${height}px` }}
        >
          Open In AR
        </Button>
      </model-viewer>
    </div>
  );
};
