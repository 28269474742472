import { ButtonDemo } from 'pages/button-demo';
import { ModelViewerDemo } from 'pages/model-viewer-demo';
import React from 'react';
import './style/main.less';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { DesenioIframe } from 'pages/desenio';
import { DesenioDemo } from 'pages/desenio-demo';

export const App: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route
        path="/demo/model-viewer"
        exact
        render={(): React.ReactNode => <ModelViewerDemo />}
      />
      <Route
        path="/demo/button-demo"
        exact
        render={(): React.ReactNode => <ButtonDemo />}
      />
      <Route
        path="/desenio/iframe"
        exact
        render={(props): React.ReactNode => <DesenioIframe {...props} />}
      />
      <Route
        path="/desenio/demo"
        exact
        render={(props): React.ReactNode => <DesenioDemo {...props} />}
      />
    </Switch>
  </Router>
);
