import { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import React, { ReactNode, useRef, useState, useEffect } from 'react';

interface ModelViewerProps {
  src: string;
  ar?: boolean;
  button?: ReactNode;
  canvas?: ReactNode;
  poster?: ReactNode;
  progressBar?: ReactNode;
  onLoadModelViewer?: (mw: ModelViewerElement) => void;
}
type OtherProps = React.DetailedHTMLProps<
  React.HTMLAttributes<ModelViewerElement>,
  ModelViewerElement
>;
export const ModelViewer: React.FunctionComponent<
  ModelViewerProps & OtherProps
> = (props) => {
  const {
    button,
    canvas,
    poster,
    progressBar: loadingBar,
    onLoadModelViewer
  } = props;
  const mwRef = useRef<ModelViewerElement>(null);
  const [listenerAdded, setListenerAdded] = useState(false);
  useEffect(() => {
    if (!mwRef.current) {
      return;
    }
    if (listenerAdded) {
      return;
    }

    setListenerAdded(true);
    mwRef.current.addEventListener('load', () => {
      if (!mwRef.current) {
        return;
      }
      if (onLoadModelViewer) {
        onLoadModelViewer(mwRef.current);
      }
    });
  }, [mwRef, listenerAdded, onLoadModelViewer]);
  return (
    <model-viewer {...props} ref={mwRef}>
      {button && <span slot="ar-button">{button}</span>}
      {canvas && <span slot="canvas">{canvas}</span>}
      {poster && <span slot="poster">{poster}</span>}
      {loadingBar && <span slot="progress-bar">{loadingBar}</span>}
    </model-viewer>
  );
};
