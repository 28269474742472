import { useState, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';

type Location = RouteProps['location'];
interface UseSearchParamsData {
  initialised: boolean;
  params: Record<string, string>;
}
export const useSearchParams = (location: Location): UseSearchParamsData => {
  const [params, setParams] = useState<Record<string, string>>({});
  const [initialised, setInitialised] = useState<boolean>(false);
  useEffect(() => {
    if (initialised) {
      return;
    }
    const usp = new URLSearchParams(location?.search);
    const paramObj = Object.fromEntries(Array.from(usp));
    setParams(paramObj);
    setInitialised(true);
  }, [location, initialised]);
  return {
    params,
    initialised
  };
};
