export const modelUrls = {
  '21x30': '/frames/21x30.glb',
  '30x40': '/frames/30x40.glb',
  '40x50': '/frames/40x50.glb',
  '50x70': '/frames/50x70.glb',
  '70x100': '/frames/70x100.glb'
};

export const imageUrls = {
  Nature: '/images/nature.jpg',
  Pineapple: '/images/pineapple.jpg',
  'Send Nudes': '/images/send-nudes.jpg',
  Architecture: '/images/abstract.jpg'
};
