import React, { useEffect, useRef, useState } from 'react';
import '@google/model-viewer/dist/model-viewer-umd';
import { modelUrls, imageUrls } from 'config';
import { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';

const frameSrc = modelUrls['21x30'];
const imageSrc = `${process.env.PUBLIC_URL}/resources${imageUrls.Architecture}`;
export const ModelViewerDemo: React.FunctionComponent = () => {
  const mwRef = useRef<ModelViewerElement>(null);
  const [listenerAdded, setListenerAdded] = useState(false);
  const width = window.innerWidth;
  const height = window.innerHeight;
  useEffect(() => {
    if (!mwRef.current) {
      return;
    }
    if (listenerAdded) {
      return;
    }

    setListenerAdded(true);
    mwRef.current.addEventListener('load', () => {
      if (!mwRef.current) {
        return;
      }
      const posterMaterial = mwRef?.current?.model?.materials[3];
      if (!posterMaterial) {
        return;
      }
      mwRef.current.createTexture(imageSrc).then((texture) => {
        posterMaterial.pbrMetallicRoughness.baseColorTexture.setTexture(
          texture
        );
      });
    });
  }, [mwRef, listenerAdded]);
  return (
    <div>
      <model-viewer
        src={`${process.env.PUBLIC_URL}/resources${frameSrc}`}
        ar
        ar-modes="scene-viewer quick-look"
        ar-placement="wall"
        camera-controls
        style={{ width: `${width}px`, height: `${height}px` }}
        ref={mwRef}
      ></model-viewer>
    </div>
  );
};
