import { Button, Col, Descriptions, Layout, Row } from 'antd';
import React from 'react';
import { RouteProps } from 'react-router-dom';
import '../style/iframe-usage.less';

const { Content } = Layout;
const { Item } = Descriptions;

const productId = '16572-4';
const width = 210;
const height = 300;
const arBtnText = 'TRY ON YOUR WALL';
const iframeSrc = `${process.env.PUBLIC_URL}/desenio/iframe?productId=${productId}&width=${width}&height=${height}&text=${arBtnText}`;

export const DesenioDemo: React.FunctionComponent<RouteProps> = () => {
  return (
    <Layout>
      <Content>
        <div className="container">
          <Row justify="space-around" gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <img
                className="product"
                alt="product"
                src={`https://desenio.se/bilder/tavelvagg/${productId}.jpg`}
              />
            </Col>
            <Col md={12} xs={24}>
              <Descriptions title="Demo Product" column={1}>
                <Item key="desc" label="Description">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Vitae vel odio doloribus repudiandae, velit placeat aperiam
                    magni eos aliquam! Numquam, impedit consequuntur unde iure
                    cumque tempora blanditiis delectus voluptatibus enim?
                  </p>
                </Item>
                <Item key="arBtn" label="">
                  <iframe
                    src={iframeSrc}
                    title="ar-btn"
                    className="ar"
                    width="200px"
                    height="32px"
                  />
                </Item>
                <Item key="cartBtn" label="">
                  <Button type="primary">Add To Cart</Button>
                </Item>
              </Descriptions>
            </Col>
            <Col xs={24}>
              <h2>Integration Details</h2>
              <p>
                To use the InnoTact AR button, simply place the following code
                snippet where you wish to place the button. Note that the button
                is not displayed on incompatible devices (desktops etc).
              </p>
              <code>
                {`
<iframe 
  src="URL"
  width="200px"
  height="32px"
  style="border-width: 0"
/>`}
              </code>

              <p>
                The width and height of the element can of course be changed as
                desired. The URL should have the following format.
              </p>
              <pre>
                <code>{`
${window.location.origin}/desenio/iframe?
  productId=PRODUCT_ID&
  width=WIDTH_MM&
  height=HEIGHT_MM&
  text=BUTTON_TEXT
`}</code>
              </pre>
              <dl>
                <dt>PRODUCT_ID</dt>
                <dd>The complete id of the product, eg 13675-4, 16077-8 etc</dd>
                <dt>WIDTH_MM</dt>
                <dd>The width in millimeters of the poster</dd>
                <dt>HEIGHT_MM</dt>
                <dd>The height in millimeters of the poster</dd>
                <dt>BUTTON_TEXT</dt>
                <dd>The text that should be displayed in the AR button</dd>
              </dl>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};
