import { ModelViewerElement } from '@google/model-viewer/lib/model-viewer';
import { LoadingOutlined } from '@ant-design/icons';
import { ModelViewer } from 'components/ModelViewer';
import { useSearchParams } from 'hooks/useSearchParams';
import React, { useCallback, useMemo, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import '../style/iframe-impl.less';

interface DesenioBtnProps {
  loading: boolean;
  disabled: boolean;
  text: string;
}

const DesenioBtn: React.FC<DesenioBtnProps> = ({ text, loading, disabled }) => (
  <button
    className={`desenio-btn${loading ? ' loading' : ''}`}
    disabled={disabled || loading}
  >
    <LoadingOutlined spin className="loading-indicator" /> {text || ' '}
  </button>
);

type DesenioIframeProps = RouteProps;

const buildModelSrc = (width: number, height: number): string =>
  `https://storage.googleapis.com/automated-desenio-prod-web/frames/v1/${
    width / 10
  }x${height / 10}.glb`;
const buildImgSrc = (productId: string): string =>
  `https://storage.googleapis.com/automated-desenio-prod-web/images/uploaded/${productId}.jpg`;
export const DesenioIframe: React.FunctionComponent<DesenioIframeProps> = (
  props
) => {
  const { params, initialised } = useSearchParams(props.location);
  const { productId, width, height, text } = params;
  const [loaded, setLoaded] = useState<boolean>(false);
  const onLoadFn = useCallback(
    (mw: ModelViewerElement) => {
      const posterMaterial = mw.model?.getMaterialByName('poster_material');
      mw.exposure = 1.2;
      mw.shadowIntensity = 0.8;
      mw.skyboxImage =
        'https://storage.googleapis.com/automated-desenio-prod-web/misc/skybox-default.hdr';

      if (!posterMaterial) {
        console.error('Poster material did not exist.');
        return;
      }
      if (!productId) {
        console.error('Product id was not passed correctly');
        return;
      }
      mw.createTexture(buildImgSrc(productId)).then((t) => {
        posterMaterial?.pbrMetallicRoughness.baseColorTexture.setTexture(t);
        setLoaded(true);
      });
    },
    [productId]
  );
  const emptyElem = useMemo(() => <span />, []);
  const btn = useMemo(
    () => (
      <DesenioBtn
        loading={!initialised || !loaded}
        disabled={!initialised || !loaded}
        text={text}
      />
    ),
    [initialised, text, loaded]
  );
  const userAgent = window.navigator.userAgent;
  const notSafari = userAgent.includes('CriOS') || userAgent.includes('FxiOS');
  if (!initialised) {
    return btn;
  }
  return (
    <ModelViewer
      ar
      ar-modes="quick-look scene-viewer webxr"
      ar-placement="wall"
      src={buildModelSrc(Number(width), Number(height))}
      onLoadModelViewer={onLoadFn}
      button={btn}
      canvas={emptyElem}
      poster={emptyElem}
      progressBar={emptyElem}
      style={{
        width: '100%',
        height: '100%',
        visibility: notSafari ? 'hidden' : 'visible'
      }}
    />
  );
};
